// export const base_url = "http://localhost:8080/api/";
// export const temp_url = "http://localhost:8080/api/";
// export const downlaodpdfurl = "http://localhost:8080";

// export const wwlaodpdSepcialfurl = "http://localhost:8080";

// export const temp_url = `${window.location.protocol}/Cdn/public/api/`;
// export const downlaodpdfurl = `${window.location.protocol}/Cdn/public`;
// export const wwlaodpdSepcialfurl = `${window.location.protocol}/Cdn/`;
// export const base_url = `${window.location.protocol}/Cdn/public/api/`;

export const base_url = "https://qmasters.alisonstech-dev.com/V2/api/";
export const temp_url = "https://qmasters.alisonstech-dev.com/V2/api/";
export const downlaodpdfurl =
  "https://qmasters.alisonstech-dev.com/V2/public";
  
export const wwlaodpdSepcialfurl =
  "https://qmasters.alisonstech-dev.com/V2/";

// export const base_url = "https://qmastercdn.alisonstech-dev.com/Cdn/api/";
// export const temp_url = "https://qmastercdn.alisonstech-dev.com/Cdn/api/";
// export const downlaodpdfurl =
//   "https://qmastercdn.alisonstech-dev.com/Cdn/public";

// export const wwlaodpdSepcialfurl =
//   "https://qmastercdn.alisonstech-dev.com/Cdn/";

// export const base_url = "http://192.168.18.212:8000/api/";
// export const temp_url = "http://192.168.18.212:8000/api/";
// export const downlaodpdfurl = "http://192.168.18.212:8000/public";

// export const wwlaodpdSepcialfurl = "http://192.168.18.212:8000/";

// export const temp_url = "http://localhost:8000/api/";
// export const base_url = "http://localhost:8000/api/";
// export const downlaodpdfurl = "http://localhost:8000/public";
// export const wwlaodpdSepcialfurl = "http://localhost:8000/";

export const encryptionSecret = "W8GYVYOxyPgezxe/vxI3SWbmem2v5Ae/uQszJPAPMhw=";
export const encryptionSecretPdf = "base64:W8GYVYOxyPgezxe/vxI3SWbmem2v5Ae/uQszJPAPMhw=";

export const logInApii = "login";
export const companyGetApi = "domainparmeter/company/active/all";

export const customerGetApii = "domainparmeter/customer/active/all";
export const customerEditApii = "domainparmeter/customer/getid?id=";
export const customerUpdateApii = "domainparmeter/customer/update";

export const customerDeleteApii = "domainparmeter/customer/delete";

export const cutomerrsGetApi = `domainparmeter/customer/active/all`;
export const recuringallget = "domainparmeter/EmailNotification/get/all";
export const registerCompany =
  "domainparmeter/company/register?company_id=" +
  localStorage.getItem("companyName");
export const taxesCompany = "domainparmeter/tax/active/all";
export const numberField = "quotation/getlastno";
export const storeQuatotion = "quotation/store";
export const updateQuatotion = "quotation/update";
export const viewQuatotion = "quotation/get/id";
export const updateCompany = "domainparmeter/company/update";
export const deleteCompany = "domainparmeter/company/delete";
export const deleteTaxes = "domainparmeter/tax/delete";
export const updateTaxes = "domainparmeter/tax/update";
export const editChartaccountt = "domainparmeter/account/update";
export const deleteAccountOFchart = "domainparmeter/account/delete";
export const taxRegister = "domainparmeter/tax/register";
export const customerRegister = "domainparmeter/customer/register";
export const customerEdit = "domainparmeter/customer/update";
export const customerDelete = "domainparmeter/customer/delete";
export const chartAccountGet = "domainparmeter/account/active/all";
export const registerAccountDataae = "domainparmeter/account/register";
export const invoiceSend = "invoice/storebyid";
export const AllViewQuatationss = "quotation/getAll";
export const invoicegetdata = "invoice/getAll";
export const challangetdata = "challan/getAll";
export const mainInvoice = "invoice/store";
export const challann = "challan/store";
export const challanupdatess = "challan/update";
export const invoicedetails = "invoice/get/id";
export const invoicesupdatee = "invoice/update";
export const challandetails = "challan/get/id";
export const quotationdetails = "quotation/get/id";
export const templatedertilss = "Templetes/get/id";
export const paymentapifetches = "PaymentVoucher/store";
export const expenseapifetches = "PaymentVoucher/expense/store";
export const paymentapiupdate = "PaymentVoucher/update";
export const templateregisteeerr = "Templetes/store";
export const paymentvoucherallgetet = "PaymentVoucher/getAll";
export const tempateAll = "Templetes/get/all";
export const tempalteeditt = "Templetes/update";
export const tempaltedeltee = "Templetes/delete";
export const fetchsendterm = "domainparmeter/terms/create";
export const termallGt = "domainparmeter/terms/get/all";
export const termallAct = "domainparmeter/terms/get/all/active";
export const getallinvoicescustomerid = "invoice/get/customer/id";
export const detailsTermms = "domainparmeter/terms/get/id";
export const termUpdatee = "domainparmeter/terms/update";
export const termDelete = "domainparmeter/terms/delete";
export const registerRollsApi = "Role/store";
export const editRoleAPI = "Role/Update";

export const allRoleApi = "Role/get";
export const assignpermissionsApi = "Permission/assign";
export const allpermissionsApi = "Permission/get";
export const getRolePermissionsApi = "Permission/get-rolepermissions/";
export const ChangePassword = "User/Password/change";
export const getCurrency = "domainparmeter/currency/get/active";
export const getDashboard = `Dashboard/get?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const getPaymentVoucherDetail = "PaymentVoucher/get/";
export const fetchrecuringemail = "domainparmeter/EmailNotification/create";
export const updaterecuringemail = "domainparmeter/EmailNotification/update";

export const AllUsers = "user_management/getAll";
export const AllUsersDetails = "user_management/getId/";
export const CreateUsers = "user_management/create";
export const UpdateUsers = "user_management/update";
export const fetchrstatus = "user_management/update/status";
// _____________________________DASHBOARD___________________________________________
export const getAllinvoiceReportsApi = `reports/all/data/graph?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const getAllquotationsReportsApi = "reports/quotations/reports";
export const getAllchallansReportsApi = `reports/chart/quotation?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const getTopRatedCustomers = `reports/top_customers?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const allreportApi = `reports/all/reports`;

export const getinvoiceexportsss = `invoice/getAll/export?paginate=all`;

export const getvoucherexportsss = `PaymentVoucher/getAll/export?paginate=all`;

export const getquotationsexportsss = `quotation/getAll/export?paginate=all`;

export const getchallansexportsss = `challan/getAll/export?paginate=all`;

export const quotationDelete = `quotation/delete`;

export const invoiceDelete = `invoice/delete`;

export const challanDelete = `challan/delete`;

export const voucherDeelete = `PaymentVoucher/delete`;

export const pdffunlcok = `pdf/password`;

export const increptionpass = `api/download`;

// _____________________________DASHBOARD___________________________________________

// ______________________________Report_____________________________________________
export const getAllledgerReportsApi = `reports/ledger/invoice?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const fetchAllChartData = `reports/chart/all/charts?company_id=${localStorage.getItem(
  "companyName"
)}`;

// ___________________________REPORTS_________________________________________

export const createapiroutebranch = "domainparmeter/branch/create";

export const createapirouteleavetype = "domainparmeter/leavetype/create";
export const apistatusbrand = "domainparmeter/branch/update/status";
export const apistatusdesgn = "domainparmeter/designation/update/status";

export const createapiroutedesignation = "domainparmeter/designation/create";

export const creategeteapiroutebranch = "domainparmeter/branch/get/all";

export const createapigteerouteleavetype = "domainparmeter/leavetype/get/all";

export const createapigeetroutedesignation =
  "domainparmeter/designation/get/all";

export const brancheditapi = "domainparmeter/branch/get/update";

export const designationedit = "domainparmeter/designation/get/update";

export const leavtpeapiedit = "domainparmeter/leavetype/get/update";

export const statusleavtypeapi = "domainparmeter/leavetype/update/status";

export const leaveall = "domainparmeter/leave/store";
export const leavemancreate = "employee/leaverequest/get/count";
export const delleaves = "domainparmeter/leave/delete";

export const employeeall = "employee/getAll";

export const employeecreate = "employee/create";
export const employeeupdate = "employee/update";

export const comppolicy = "domainparmeter/companypolicy/get";

export const policyCrc = "domainparmeter/companypolicy/update";
export const manageleaveecreate = "employee/leaverequest/create";
export const managleaveget = "employee/leaverequest/get";
export const leavesedit = "employee/leaverequest/update";
export const leavesreq = "employee/leaverequest/approved";
export const getloans = "employee/loan/get/all";
export const loanscrc = "employee/loan/create";
export const leavesreqcancel = "employee/leaverequest/cancel";
export const leavesreqreject = "employee/leaverequest/reject";
export const rosterrcrc = "roster/create";
export const loantypeall = "employee/loanType/get";
export const loansedit = "employee/loan/update";
export const loanscrcreturn = "employee/loan/return/create";
export const getattendance = "attendance/get";
export const getpayroll = "employee/payroll/get/all";
export const payrollcreate = "employee/appraisal/create";
export const attendancecreate = "attendance/csv/upload";
export const attendanceextract = "attendance/extract";

export const employeeallowance = "employee/get/allowances";
export const empsalary = "employee/Salary/set/create";
export const employeegetsalryyy = "employee/Salary/set/get";

export const payrollAllowance = "employee/Salary/set/get";

export const empsalaryupdate = "employee/Salary/set/update";

export const satdeductupdate = "employee/Salary/set/update";

export const otherpayments = "employee/Salary/set/update";
export const deletepayrollemloyeeexpense = "employee/Salary/set/delete";
export const createsalaryapi = "employee/Salary/genrate/create";
export const getttallowance = "employee/get/allowances";
export const allowcreate = "employee/get/allowances/create";

export const getdeductions = "employee/get/deduction";

export const crcdeduction = "employee/get/deduction/create";

export const getcurrency = "domainparmeter/currency/get/active";

export const currencycreate = "domainparmeter/currency/create";

export const currencyupdate = "domainparmeter/currency/edit";
export const currencydelete = "domainparmeter/currency/delete";

export const allowupdate = "employee/get/allowances/edit";
export const allowdelete = "employee/get/allowances/delete";

export const editdeduction = "employee/get/deduction/edit";

export const deletededuction = "employee/get/deduction/delete";

export const generateslryyy = "employee/Salary/genrate/store";
export const generateslryyyedit = "employee/Salary/set/salaryupdate";
export const getpayslip = "employee/Salary/pay/get";
export const getpaysalary = "employee/Salary/pay/store";

// export const getleavemangapi = "domainparmeter/companypolicy/";
